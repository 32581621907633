$(function() {
  AOS.init();
});

// hamburger menu
$('#hamburger').on('click', function() {
    $(this).toggleClass('is-open');
    $('#main-nav').toggleClass('is-nav-open');
    $('.menu-social').toggleClass('is-active');
    $('#language-switcher').toggleClass('is-active');
    $('#black-wall').toggleClass('is-nav-open');
});

// black wall click
$('#black-wall').on('click', function() {
    $(this).toggleClass('is-nav-open');
    $('#main-nav').toggleClass('is-nav-open');
    $('.menu-social').toggleClass('is-active');
    $('#language-switcher').toggleClass('is-active');
    $('#hamburger').toggleClass('is-open');
});

$(".nested-li").hover(function(){
    setTimeout(() => {
        $('.href-on-mobile').addClass('is-show');
    }, 1);
    $('.nested-nav-bottom').addClass('is-show');
  }, function(){
    $('.nested-nav-bottom').removeClass('is-show');
    // $('.href-on-mobile').removeClass('is-show');
    setTimeout(() => {
      $('.href-on-mobile').removeClass('is-show');
  }, 1);
});


$(".href-on-mobile, .nested-nav-bottom").hover(function(){
    $('.href-on-mobile').addClass('is-show');
    $('.nested-nav-bottom').addClass('is-show');
  }, function(){
    $('.nested-nav-bottom').removeClass('is-show');
    $('.href-on-mobile').removeClass('is-show');
});

let menu = []
$('.to-pagination').map(function() {
  const text = $(this).html()
  menu.push(text);
})

const mySwiper = new Swiper ('#home-slider', {
  // Optional parameters
  // direction: 'vertical',
  // loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, classNames) {
      return '<span class="' + classNames + '">' + (menu[index]) + '</span>';
    },
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },

  on: {
    slideNextTransitionStart: function () {
      setTimeout(() => {
        mySwiperImages.slideNext()
      }, 0);
    
    },

    slidePrevTransitionStart: function () {
      setTimeout(() => {
        mySwiperImages.slidePrev()
      }, 0);
    },
  },
})

const mySwiperImages = new Swiper ('#home-slider-images', {
  // Optional parameters
  direction: 'vertical',
  effect: 'fade',
  loop: true,
})

// const mySwiperImages = new Swiper ('#home-slider-images', {
//   // Optional parameters
//   direction: 'vertical',
//   effect: 'fade',
//   loop: true,
// })

